import { http } from './config'
import authHeader from './auth-header';

export default {


    

    total_cobranca_reneg_volume: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
        }
        return http.get('/cobranca-dash/total-cobranca-reneg-volume/', {
           params: params,
           headers: authHeader()   
        })
    },

    acordo_composicao: (dataI, dataF, nomeEmpresa, tipo, produto, periodo) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'produto': produto,
            'periodo': periodo,
        }
        return http.get('/cobranca-dash/lista-composicao/', {
            params: params,
            headers: authHeader()
        })        
    },

    lista_negativados: (dataI, dataF, nomeEmpresa, tipo, produto, opcao) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'produto': produto,
            'opcao': opcao
        }
        return http.get('/cobranca-dash/negativados/', {
            params: params,
            headers: authHeader()
        })        
    },


    lista_remessa: (dataI, dataF, nomeEmpresa, tipo, produto, opcao) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'produto': produto,
            'opcao': opcao
        }
        return http.get('/cobranca-dash/lista-remessa/', {
            params: params,
            headers: authHeader()
        })        
    },

    lote_txt: (lote, tipo) => {

        const params = {
            'lote': lote,
             'tipo': tipo
          };

       
          return http.get('/cobranca-dash/lista-remessa-download/', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });

          
    },


    lista_composicao_xls: (dataI, dataF, nomeEmpresa, tipo, produto, periodo) => {

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'produto': produto,
            'periodo': periodo,

          };

       
          return http.get('/planilha-dash/lista-composicao-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });

          
    },

    lista_negativado_xls: (dataI, dataF, nomeEmpresa, tipo, produto, opcao, opcao2) => {

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'produto': produto,
            'opcao': opcao,
            'opcao2': opcao2,

          };

       
          return http.get('/planilha-dash/negativados-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });

          
    },

    total_cobranca_reneg_cancelada: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
        }

        return http.get('/cobranca-dash/total-cobranca-reneg-cancelada/', {
           params: params,
           headers: authHeader() 
              
        })
    },

    total_cobranca_reneg_ativa: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
        }

        return http.get('/cobranca-dash/total-cobranca-reneg-ativa/', {
           params: params,
           headers: authHeader() 
              
        })
    },
    

    total_cobranca_reneg_volume_r: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
        }
        return http.get('/cobranca-dash/total-cobranca-reneg-volume-r/', {
           params: params,
           headers: authHeader()   
        })
    },

    total_cobranca_reneg_cancelada_r: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
        }

        return http.get('/cobranca-dash/total-cobranca-reneg-cancelada-r/', {
           params: params,
           headers: authHeader() 
              
        })
    },

    total_cobranca_reneg_ativa_r: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
        }

        return http.get('/cobranca-dash/total-cobranca-reneg-ativa-r/', {
           params: params,
           headers: authHeader() 
              
        })
    },

    
    lista_reneg_cobranca_xls: (dataI, dataF, nomeEmpresa, tipo, status) => {

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'status': status
          };

       
          return http.get('/planilha-dash/lista-reneg-cobranca-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });

          
    },


    

    ranking: (dataI, dataF, nomeEmpresa) => {
      
                const params = {
                    'data-inicial': dataI,
                    'data-final': dataF,
                    'empresa': nomeEmpresa,
                  };
        
               

    return http.get('/cobranca-dash/lista-ranking/', {
       params: params,
       headers: authHeader()   
    })  
},
    

   

}
